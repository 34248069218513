<template>
  <div class="studentInfo">
    <div class="s-info-cont">
      <el-steps :active="active" align-center>
        <el-step title="个人基本信息"></el-step>
        <el-step title="学籍管理"></el-step>
        <el-step title="账号管理"></el-step>
      </el-steps>
      <el-form label-position="right" :model="personForm" :rules="pRules" ref="personForm" label-width="100px" class="demo-form" v-show="active == 1" :disabled="$route.query.status == 'watchs'">
        <el-form-item label="姓名:" prop="name">
          <el-input v-model="personForm.name" @input="trimLR('name')"></el-input>
        </el-form-item>
        <el-form-item label="性别:" prop="sex">
          <el-radio-group v-model="personForm.sex">
            <el-radio :label="0">男</el-radio>
            <el-radio :label="1">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="民族:">
          <el-input v-model="personForm.nation" @input="trimLR('nation')"></el-input>
        </el-form-item>
        <el-form-item label="政治面貌:">
          <el-select v-model="personForm.politicsStatus" placeholder="请选择">
            <el-option
              v-for="item in faceData"
              :key="item.value"
              :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="证件类型:">
          <el-input value="身份证" disabled></el-input>
        </el-form-item>
        <el-form-item label="身份证号:" prop="cardId">
          <el-input v-model="personForm.cardId" @input="trimLR('cardId')"></el-input>
        </el-form-item>
        <!-- <el-form-item label="出生日期:" prop="dateBirth">
          <el-date-picker
            v-model="personForm.dateBirth"
            type="date"
            value-format="timestamp"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item> -->
        <el-form-item label="学生来源:" prop="source">
          <el-input v-model="personForm.source" @input="trimLR('source')"></el-input>
        </el-form-item>
        <el-form-item label="工作单位:" prop="unit">
          <el-input v-model="personForm.unit" @input="trimLR('unit')"></el-input>
        </el-form-item>
        <el-form-item label="手机号码:" prop="phone">
          <el-input v-model="personForm.phone" @input="trimLR('phone')" maxlength="16"></el-input>
        </el-form-item>
        <el-form-item label="通讯地址:" prop="address">
          <el-input v-model="personForm.address" @input="trimLR('address')"></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱:" prop="email">
          <el-input v-model="personForm.email" @input="trimLR('email')"></el-input>
        </el-form-item>
      </el-form>

      <el-form label-position="right" :model="studentForm" :rules="sRules" ref="studentForm" label-width="100px" class="demo-form" v-show="active == 2" :disabled="$route.query.status == 'watchs'">
        <el-form-item label="学籍照片:" prop="photoUrl">
          <el-button v-if="!studentForm.photoUrl" @click="getLocalPic">选择文件</el-button>
          <template v-else>
            <el-image class="pic_show" :src="studentForm.photoUrl" alt="" @click="getLocalPic"></el-image>
            <span class="preview" @click="watchEvent([studentForm.photoUrl])">在线预览</span>
          </template>
          <div>图片格式为：500X500像素，透明低png,jpg,gif图片</div>
        </el-form-item>
        <el-form-item label="考生号:" prop="examNum">
          <el-input maxlength="16" v-model="studentForm.examNum" @input="trimLR('examNum')" :disabled="type == 'edit'"></el-input>
        </el-form-item>
        <el-form-item label="学号:" prop="studentNum">
          <el-input maxlength="16" v-model="studentForm.studentNum" @input="trimLR('studentNum')" :disabled="type == 'edit'"></el-input>
        </el-form-item>
        <el-form-item label="入学批次:" prop="admissionBatch">
          <el-select v-model="studentForm.admissionBatch" placeholder="请选择" :disabled="$route.query.status == 'edit'">
            <el-option
              v-for="item in schoolBatchData"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入学时间:" prop="admissionTime">
          <el-date-picker
            v-model="studentForm.admissionTime"
            type="date"
            value-format="timestamp"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="毕业时间:" prop="graduationTime">
          <el-date-picker
            v-model="studentForm.graduationTime"
            type="date"
            value-format="timestamp"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="层次:" prop="eduCategory">
          <el-select
            :disabled="$route.query.status == 'edit'"
            v-model="studentForm.eduCategory"
            placeholder="层次"
            @change="changeEdu"
            filterable
            clearable>
            <el-option v-for="(item, key, index) in $common.getEduCategory" :key="index" :label="item" :value="Number(key)"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学制:" prop="learningSystem">
          <el-select v-model="studentForm.learningSystem" placeholder="请选择">
            <el-option
              v-for="item in schoolSystem"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学习形式:" prop="stuType">
          <el-select v-model="studentForm.stuType" placeholder="请选择">
            <el-option
              v-for="item in stuTypeData"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业:" prop="majorId">
          <el-select v-model="studentForm.majorId" placeholder="请选择" @focus="getMajorDataEvent" filterable clearable :disabled="$route.query.status == 'edit'">
            <el-option
              v-for="item in majorNameData"
              :key="item.majorId"
              :label="item.majorName"
              :value="item.majorId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="教学点:" prop="stationId">
          <el-select v-model="studentForm.stationId" placeholder="请选择" clearable>
            <el-option
              v-for="item in correspondenceStation"
              :key="item.stationsId"
              :label="item.stationsName"
              :value="item.stationsId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学籍状态:" prop="studentStatus">
          <el-select v-model="studentForm.studentStatus" placeholder="请选择">
            <el-option
              v-for="item in studentOption"
              :key="item.label"
              :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-form label-position="right" :model="accountForm" :rules="aRules" ref="accountForm" label-width="100px" class="demo-form" v-show="active == 3">
        <el-form-item label="平台账号:" prop="loginNum" v-if="type == 'add'">
          <el-input v-model="accountForm.loginNum" @input="trimLR('loginNum')">
            <template slot="prepend">{{prefixValues}}</template>
          </el-input>
        </el-form-item>
        <el-form-item label="平台账号:" v-if="type == 'edit' || type == 'watchs'" prop="loginNum">
          <el-input v-model="accountForm.loginNum" disabled></el-input>
        </el-form-item>
        <el-form-item label="账号密码:" prop="password" v-if="type == 'add'">
          <el-input
            type="password"
            @input="trimLR('password')"
            v-model="accountForm.password"
            show-password
            placeholder="请输入正确的密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" prop="secondPassword" v-if="type == 'add'">
          <el-input
            type="password"
            @input="trimLR('secondPassword')"
            v-model="accountForm.secondPassword"
            show-password
            placeholder="请再次确认密码"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="btn_area">
        <el-button @click="goBack" v-show="active == 1">返回</el-button>
        <el-button @click="stageEvent('last')" v-show="active == 2 || active == 3">上一步</el-button>
        <el-button type="primary" @click="stageEvent('next')" v-show="active == 1 || active == 2">下一步</el-button>
        <el-button v-show="active == 3" type="primary" @click="saveEvent">保存</el-button>
      </div>
    </div>

    <el-image-viewer
      v-if="showViewer"
      :on-close="watchEvent"
      :url-list="voucherUrlsPic"
    />
    <input style="display: none;" type="file" @change="picChange" ref="loadPic" accept=".jpg,.png,.gif" />
  </div>
</template>

<script>
import way from '../../api/encapsulation'
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import uploadAli from "@/api/uploadAli";
export default {
  name: 'studentInfo',
  components: {
    ElImageViewer
  },
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value !== this.accountForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validPhone = (rule, value, callback) => {
      if(value) callback(way.regPhone(value))
      else callback();
    }
    var validateInput = (rule, value, callback) => {
      callback(way.regSpecil(value));
    };
    var validChinese = (rule, value, callback) => {
      callback(way.regChinese(value))
    };
    var validNumletter = (rule, value, callback) => {
      callback(way.regNumletter(value));
    };
    var validIDCard = (rule, value, callback) => {
      if(!way.regIDCard(value)) {
        callback(new Error("身份证格式有误"));
      } else {
        callback();
      }
    }
    return {
      type: '',
      active: 1,
      prefixValues: this.$route.query.encode,
      // ruleForm: {
      //   address: '',
      //   admissionBatch: '',
      //   admissionTime: '',
      //   cardId: '',
      //   dateBirth: '',
      //   eduCategory: 0,
      //   email: '',
      //   examNum: '',
      //   graduationTime: '',
      //   learningSystem: 1,
      //   loginNum: '',
      //   majorId: '',
      //   name: '',
      //   nation: '',
      //   password: '',
      //   phone: '',
      //   photoUrl: '',
      //   politicsStatus: '群众',
      //   secondPassword: '',
      //   sex: 0,
      //   source: '',
      //   stationId: '',
      //   stuType: 0,
      //   studentNum: '',
      //   studentStatus: 0,
      //   unit: ''
      // },
      personForm: {
        tenantId: null,
        name: null,
        sex: 0,
        nation: null,
        politicsStatus: '群众',
        cardId: null,
        // dateBirth: null,
        source: null,
        unit: null,
        phone: null,
        address: null,
        email: null
      },
      studentForm: {
        photoUrl: null,
        examNum: null,
        studentNum: null,
        admissionBatch: null,
        admissionTime: null,
        graduationTime: null,
        eduCategory: 0,
        learningSystem: 1,
        stuType: 0,
        majorId: null,
        stationId: null,
        studentStatus: '在读'
      },
      accountForm: {
        loginNum: null,
        password: null,
        secondPassword: null
      },
      faceData: [
        {
          value: 0,
          label: '群众'
        },
        {
          value: 1,
          label: '中共党员'
        },
        {
          value: 2,
          label: '中共预备党员'
        },
        {
          value: 3,
          label: '共青团员'
        },
        {
          value: 4,
          label: '民革会员'
        },
        {
          value: 5,
          label: '民盟盟员'
        },
        {
          value: 6,
          label: '民建会员'
        },
        {
          value: 7,
          label: '民进会员'
        },
        {
          value: 8,
          label: '农工党党员'
        },
        {
          value: 9,
          label: '致公党党员'
        },
        {
          value: 10,
          label: '九三学社社员'
        },
        {
          value: 11,
          label: '台盟盟员'
        },
        {
          value: 12,
          label: '无党派民主人士'
        },
      ],
      schoolSystem: [
        {
          value: 1,
          label: 1
        },
        {
          value: 2,
          label: 2
        },
        {
          value: 2.5,
          label: 2.5
        },
        {
          value: 3,
          label: 3
        },
        {
          value: 4,
          label: 4
        },
        {
          value: 5,
          label: 5
        }
      ],
      stuTypeData: [
        {
          value: 0,
          label: '函授'
        },
        {
          value: 1,
          label: '脱产'
        },
        {
          value: 2,
          label: '业余'
        },
        {
          value: 3,
          label: '全日制'
        }
      ],
      majorNameData: [],
      correspondenceStation: [],
      studentOption: [],
      // rules: {
      //   name: [
      //     { required: true, message: '请输入姓名', trigger: 'blur' },
      //   ],
      //   sex: [
      //     { required: true, message: '请选择性别', trigger: 'blur' },
      //   ],
      //   nation: [
      //     { required: true, message: '请输入民族', trigger: 'blur' },
      //   ],
      //   politicsStatus: [
      //     { required: true, message: '请选择政治面貌', trigger: 'blur' },
      //   ],
      //   cardId: [
      //     { required: true, message: '请输入身份证号', trigger: 'blur' },
      //   ],
      //   studentNum: [
      //     { required: true, message: '请输入学号', trigger: 'blur' },
      //   ],
      //   admissionBatch: [
      //     { required: true, message: '请选择入学批次', trigger: 'blur' },
      //   ],
      //   admissionTime: [
      //     { required: true, message: '请选择入学时间', trigger: 'blur' },
      //   ],
      //   eduCategory: [
      //     { required: true, message: '请选择层次', trigger: 'blur' },
      //   ],
      //   learningSystem: [
      //     { required: true, message: '请选择学制', trigger: 'blur' },
      //   ],
      //   majorId: [
      //     { required: true, message: '请选择专业', trigger: 'blur' },
      //   ],
      //   stationId: [
      //     { required: true, message: '请选择教学点', trigger: 'blur' },
      //   ],
      //   studentStatus: [
      //     { required: true, message: '请选择学籍状态', trigger: 'blur' },
      //   ],
      //   loginNum: [
      //     { required: true, message: '请输入平台账号', trigger: 'blur' },
      //   ],
      //   password: [
      //     { required: true, message: '请输入账号密码', trigger: 'blur' },
      //     {
      //       min: 8,
      //       max: 16,
      //       message: "长度在 8 到 16 个字符",
      //       trigger: "blur",
      //     },
      //     { validator: validateInput, trigger: ["blur", "change"] },
      //     { validator: validNumletter, trigger: ["blur", "change"] },
      //   ],
      //   secondPassword: [
      //     { required: true, message: '请输入确认密码', trigger: 'blur' },
      //     { validator: validatePass2, trigger: "blur" },
      //     {
      //       min: 8,
      //       max: 16,
      //       message: "长度在 8 到 16 个字符",
      //       trigger: "blur",
      //     },
      //     { validator: validateInput, trigger: ["blur", "change"] },
      //     { validator: validNumletter, trigger: ["blur", "change"] },
      //   ],
      // },
      pRules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'blur' }
        ],
        // nation: [
        //   { required: true, message: '请输入民族', trigger: 'blur' }
        // ],
        // politicsStatus: [
        //   { required: true, message: '请选择政治面貌', trigger: 'blur' }
        // ],
        cardId: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { validator: validIDCard, trigger: ["blur", "change"] }
        ],
        phone: [
          { validator: validChinese, trigger: ["blur", "change"] }
        ]
      },
      sRules: {
        examNum: [
          { required: true, message: '请输入考生号', trigger: 'blur' }
        ],
        studentNum: [
          { required: true, message: '请输入学号', trigger: 'blur' }
        ],
        admissionBatch: [
          { required: true, message: '请选择入学批次', trigger: 'blur' }
        ],
        admissionTime: [
          { required: true, message: '请选择入学时间', trigger: 'blur' }
        ],
        eduCategory: [
          { required: true, message: '请选择层次', trigger: 'blur' }
        ],
        learningSystem: [
          { required: true, message: '请选择学制', trigger: 'blur' }
        ],
        majorId: [
          { required: true, message: '请选择专业', trigger: 'blur' }
        ],
        stationId: [
          { required: true, message: '请选择教学点', trigger: 'blur' }
        ],
        studentStatus: [
          { required: true, message: '请选择学籍状态', trigger: 'blur' }
        ]
      },
      aRules: {
        loginNum: [
          { required: true, message: '请输入平台账号', trigger: 'blur' },
          { validator: validChinese, trigger: ["blur", "change"] },
          { validator: validateInput, trigger: ["blur", "change"] }
        ],
        password: [
          { required: true, message: '请输入账号密码', trigger: 'blur' },
          {
            min: 8,
            max: 16,
            message: "长度在 8 到 16 个字符",
            trigger: "blur",
          },
          // { validator: validateInput, trigger: ["blur", "change"] },
          { validator: validNumletter, trigger: ["blur", "change"] }
        ],
        secondPassword: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: validatePass2, trigger: "blur" },
          {
            min: 8,
            max: 16,
            message: "长度在 8 到 16 个字符",
            trigger: "blur",
          },
          // { validator: validateInput, trigger: ["blur", "change"] },
          { validator: validNumletter, trigger: ["blur", "change"] }
        ]
      },
      showViewer: false,
      voucherUrlsPic: [],
      schoolBatchData: [],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        this.personForm.tenantId = newVal.query.tenantId;
        if(newVal.query.hasOwnProperty('status')) {
          this.type = newVal.query.status;
          if(newVal.query.status == 'edit' || newVal.query.status == 'watchs') {
            this.getStudentData(this.$route.query.id);
          }
          this.getTheDropDownBoxData();
          this.getGoSchoolData();
          this.getDictItemByType();
        }
      }
    }
  },
  methods: {
    // 获取学籍状态数据
    getDictItemByType() {
      this.$request
        .getDictItemByType({ type: "student_status" })
        .then((res) => {
          if (res.data.code == 0) {
            this.studentOption = res.data.data;
          }
        });
    },
    trimLR(name) {
      if(this.personForm.hasOwnProperty(name)) {
        this.personForm[name] = way.clearSpace(this.personForm[name]);
      } else if(this.studentForm.hasOwnProperty(name)) {
        this.studentForm[name] = way.clearSpace(this.studentForm[name]);
      } else if(this.accountForm.hasOwnProperty(name)) {
        this.accountForm[name] = way.clearSpace(this.accountForm[name]);
      }
    },
    changeEdu(val) {
      this.studentForm.majorId = null;
      this.getMajorDataEvent();
    },
    // 专业数据
    getMajorDataEvent() {
      let tenantId = this.$route.query.tenantId;
      let eduCategory = this.studentForm.eduCategory;
      this.$request.getAddStudentMajorData({tenantId,eduCategory}, res=>{
        if(res.code == 0) {
          this.majorNameData = res.data;
        }
      })
    },
    // 获取教学点数据
    getTheDropDownBoxData() {
      let obj = {
        tenantId: this.$route.query.tenantId
      }
      console.log(obj)
      this.$request.getTheDropDownBoxs(obj, res => {
        if(res.code == 0){
          this.correspondenceStation = res.data;
        }
      })
    },
    // 获取入学批次数据
    getGoSchoolData() {
      // if(this.schoolBatchData.length != 0) return;
      let obj = {
        tenantId: this.$route.query.tenantId
      }
      this.$request.getGoSchool(obj, res => {
        if(res.code == 0) {
          this.schoolBatchData = res.data;
        }
      })
    },
    getStudentData(id) {
      this.$request.queryToId({id}, res => {
        if(res.code == 0) {
          res.data.politicsStatus = res.data.politicsStatus;
          let data = res.data;
          this.personForm.name = data.name;
          this.personForm.sex = data.sex;
          this.personForm.nation = data.nation;
          this.personForm.politicsStatus = data.politicsStatus;
          this.personForm.cardId = data.cardId;
          // this.personForm.dateBirth = data.dateBirth;
          this.personForm.source = data.source;
          this.personForm.unit = data.unit;
          this.personForm.phone = data.phone;
          this.personForm.address = data.address;
          this.personForm.email = data.email;
          this.studentForm.photoUrl = data.photoUrl;
          this.studentForm.examNum = data.examNum;
          this.studentForm.studentNum = data.studentNum;
          this.studentForm.admissionBatch = data.admissionBatch;
          this.studentForm.admissionTime = data.admissionTime;
          this.studentForm.graduationTime = data.graduationTime;
          this.studentForm.eduCategory = String(data.eduCategory);
          this.studentForm.learningSystem = data.learningSystem;
          this.studentForm.stuType = data.stuType;
          this.studentForm.majorId = data.majorId;
          this.studentForm.stationId = data.stationId;
          this.studentForm.studentStatus = data.studentStatus;
          this.accountForm.loginNum = data.loginNum;
          this.accountForm.password = data.password;
          this.accountForm.secondPassword = data.secondPassword;
          this.accountForm.id = this.$route.query.id;
          this.getMajorDataEvent();
        }
      })
    },
    goBack() {
      this.$router.go(-1);
    },
    stageEvent(type) {
      if(type == 'last') {
        this.active = --this.active;
      } else if(type == 'next') {
        let {admissionTime, graduationTime} = this.studentForm
        if(admissionTime && graduationTime) {
          if(admissionTime > graduationTime) {
            this.Warn('入学时间不大于毕业时间')
            return;
          }
        }
        let formname = this.active == 1 ? 'personForm' : this.active == 2 ? 'studentForm' : 'accountForm'
        this.$refs[formname].validate((valid) => {
          if (valid) {
            this.active = ++this.active;
          } else {
            return false;
          }
        });
      }
    },
    saveEvent() {
      if(this.$route.query.status == 'watchs') {
        this.$router.go(-1);
        return;
      }
      this.$refs['accountForm'].validate((valid) => {
        if (valid) {
          if(this.type == 'add') {
            this.addStudent();
          } else if(this.type == 'edit') {
            this.editStudent();
          }
        } else {
          return false;
        }
      });
    },
    addStudent() {
      let obj = Object.assign({}, this.personForm, this.studentForm, this.accountForm);
      obj.loginNum = this.prefixValues + obj.loginNum;
      this.$request.addStudents(obj, res => {
        if(res.code == 0) {
          this.Success('添加成功')
          this.goBack();
        }
      })
    },
    editStudent() {
      let obj = Object.assign({}, this.personForm, this.studentForm, this.accountForm);
      this.$request.updateStudent(obj).then(res => {
        if(res.data.code == 0) {
          this.Success('编辑成功')
          this.goBack();
        }
      })
    },
    getLocalPic() {
      this.$refs.loadPic.value= '';
      this.$refs.loadPic.click();
    },
    picChange() {
      let inputDOM = this.$refs.loadPic;
      let files = inputDOM.files[0];
      let isFileType = files.type
      // if(isFileType != 'image/png' || isFileType != 'image/jpeg') {
      //   this.Warn('上传图片只能是 PNG 或 JPG 格式!');
      //   return;
      // }
      let that = this;
      var reader = new FileReader();
      reader.onload = function(e) {
        var data = e.target.result;
        //加载图片获取图片真实宽度和高度
        var image = new Image();
        image.onload=function(){
            var width = image.width;
            var height = image.height;
            // if(width != 500 && height != 500) {
            //   that.Warn('上传图片的尺寸只能为500X500')
            //   return;
            // }
            that.upLoadEvent(files);
        };
        image.src = data;
      };
      reader.readAsDataURL(files);
    },
    async upLoadEvent(file) {
      let obj = await uploadAli.imgFileUploadOss(file, 'img', 3)
      this.studentForm.photoUrl = obj.excelUrl
      // this.$request.uploadPic(file, res=>{
      //   console.log(res)
      //   if(res.code == 0) {
      //     let ossUrl = res.data;
      //     this.studentForm.photoUrl = ossUrl;
      //   }
      // })
    },
    watchEvent(urlArr) {
      this.showViewer = !this.showViewer;
      if(this.showViewer) {
        this.voucherUrlsPic = urlArr;
      }
    },
  },
}
</script>

<style lang='less'>
  .studentInfo {
    background-color: #fff;
    padding: 32px 0 30px 0;
    .s-info-cont {
      width: 600px;
      margin: 0 auto;
      .demo-form {
        margin-top: 50px;
      }
      .btn_area {
        text-align: center;
      }
    }
    .pic_show {
      width: 60px;
      height: 60px;
    }
    .preview {
      margin-left: 20px;
      cursor: pointer;
      &:hover {
        color: #1282FF;
      }
    }
  }
</style>
